.loading-layout {
  background-color: rgba(0, 37, 64, 1); // used because UI lib is not loaded on this stage
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -8px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loading-icon {
  animation: rotate 0.6s infinite linear;
  height: 30px;
  width: 30px;
  color: rgba(0, 149, 255, 1); // used because UI lib is not loaded on this stage
}
